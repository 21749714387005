

.div404{

  height: 95vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: start;

  margin-left: 22vw;

  h2{
    align-self: start;
    font-size: 200px;
    -webkit-text-stroke: 3px #FF9797;
    color: rgba(0, 0, 0, 0);
    margin-top: 5vh;
    margin-bottom: 0;

  }

  h4{
    text-align: start;

    font-size: 90px;
    -webkit-text-stroke: 3px #FF9797;
    color: rgba(0, 0, 0, 0);

    margin-top: 0;
    margin-bottom: 0;
  }

  .retour404{
    align-self: start;
    margin-top: 5vh;

    i{
      font-size: 75px;
      color: rgba(0, 0, 0, 0);
      -webkit-text-stroke: 3px #FF9797;

      transition: 0.5s;

      &:hover{
        color: #FF9797;
      }

    }

  }

}


.papineau404{
  position: fixed;
  z-index: -100;
  bottom: 0;
}




@media screen and (max-width: 1375px) {

  .div404{

    h2{
      font-size: 150px;
    }

    h4{
      font-size: 70px;
    }

    .retour404 {
      i{
        font-size: 50px;
      }
    }

  }

  .papineau404{
    right: 0;
  }

}


@media screen and (max-width: 1150px) {
  .div404{
    margin-left: 5vw;
  }
}


@media screen and (max-width: 950px) {
  .div404{

    h2{
      font-size: 150px;
    }

  }

  .papineau404{
    right: -300px;
  }
}



@media screen and (max-width: 450px) {
  .div404{

    h2{
      margin-top: -25vh;
      font-size: 150px;
    }

    h4{
      font-size: 40px;
      -webkit-text-stroke: 1px #FF9797;
    }

  }

  .papineau404{
    bottom: -90vh;
    right: -200px;
  }
}





