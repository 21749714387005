

.hero{

  height: 100vh;

  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: row;

  margin-bottom: 25vh;

  div{
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 5%;
    margin-right: 5%;

    h1{
      margin: 0;
      padding: 0;

      font-family: 'Reenie Beanie', cursive;
      font-weight: normal;
      text-transform: uppercase;

      font-size: 45px;
      line-height: 82.4%;
      letter-spacing: 30px;
    }

    h2{
      font-family: 'Reenie Beanie', cursive;
      font-weight: normal;
      text-transform: uppercase;

      font-size: 30px;
      line-height: 82.4%;
      letter-spacing: 10px;
    }

  }

  img{
    margin-top: 26vh;
    height: 50vh;
  }

  .btnCV{

    margin-top: 9vh;
    margin-left: 5vw;

    p{
      margin-left: 30%;
    }

  }

}

@media screen and (max-width: $breakpoint-md) {

  .hero{
    .wrapper{

      display: flex;
      flex-direction: row;

      h1{
        writing-mode: vertical-rl;
        text-orientation: upright;

        letter-spacing: 2px;
      }

      h2{
        display: none;
      }

    }

    div{
      img{
        height: 40vh;
        width: auto;
      }
    }

  }



}