

.divExitMenu{

  position: fixed;
  left: 0.5vw;
  top: 40vh;
  z-index: 1000;

  visibility: hidden;
  opacity: 0;
  transition: 1s;

  a{
    text-decoration: none;
    color: #878787;

    .menuExit{

      font-family: "Roboto", Helvetica, Arial, sans-serif;
      font-size: 38px;
      font-weight: bold;

      margin-left: 1.8vw;
    }

    &:hover{
      color: $white;

      cursor: pointer;
    }

  }

}



.menu{

  visibility: hidden;
  opacity: 0;

  transition: opacity 1s ease-in-out;

  position: fixed;

  height: 100vh;
  width: 100vw;

  background-color: $color-bg-page;

  .listeMenu{

    height: 93vh;
    margin-left: 15vw;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    ul{
      margin-top: 9vh;

      a{
        text-decoration: none;

        li{
          position: relative;

          font-family: "Roboto", Helvetica, Arial, sans-serif;
          font-weight: bolder;
          font-size: 41px;
          color: #878787;
          text-transform: uppercase;
          letter-spacing: 10px;
          line-height: 200%;

          list-style: none;
          text-align: start;

        }

      }
    }
  }


  i{
    position: fixed;
    right: 3vw;

    color: white;

    font-size: 30px;

    &:hover{

      color: #AD6B6B;

    }

  }

}




@media screen and (max-width: $breakpoint-md) {

  .divExitMenu {
    display: block;
    position: fixed;
    left: auto;
    right: 2vw;
    top: -1vh;

    width: 45px;
  }

  .menu {

    .listeMenu {

      ul {

        a {

          li {
            margin-top: 5vh;
            color: white;
          }
        }
      }
    }
  }
}


@media screen and (min-width: $breakpoint-md) {

  .menu {

    .listeMenu {

      ul {

        a {
            .liAccueil{

              &:hover{
                color: $white;
                transition: 0.1s;

                &:before{
                  transition: 1s;
                  border-bottom: 10px solid #AD6B6B;
                  position: absolute;
                  content: "ACCUEIL";
                  width: 150%;
                  height: 55%;
                }

              }

            }

            .liProjets{

              &:hover{
                color: $white;
                transition: 0.1s;

                &:before{
                  transition: 1s;
                  border-bottom: 10px solid #AD6B6B;
                  position: absolute;
                  content: "PROJETS";
                  width: 150%;
                  height: 55%;
                }

              }

            }

            .liContact{

              &:hover{
                color: $white;
                transition: 0.1s;

                &:before{
                  transition: 1s;
                  border-bottom: 10px solid #AD6B6B;
                  position: absolute;
                  content: "CONTACT";
                  width: 150%;
                  height: 55%;
                }

              }

            }

            .liApropos{

              &:hover{
                color: $white;
                transition: 0.1s;

                &:before{
                  transition: 1s;
                  border-bottom: 10px solid #AD6B6B;
                  position: absolute;
                  content: "À PROPOS";
                  width: 150%;
                  height: 55%;
                }

              }

            }
        }
      }
    }
  }


}
