

.divAPropos{

  padding-left: 25vw;

  width: 95vw;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h2{
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 67px;
  }

  div{
    display: flex;
    flex-direction: row;
    align-items: center;

    .imageAPropos{
      height: 40vh;
      width: 25vw;
    }

    p{

      text-align: left;

      width: 25vw;
    }

  }

  .btnCV{
    margin-top: 5vh;
  }

}

.footerAPropos{

  margin-top: 13vh;
  margin-bottom: 0;

}


@media screen and (max-width: 1190px) {

  .divAPropos {


    h2 {
      padding-top: 13vh;

      font-size: 40px;
    }

    div {

      .imageAPropos{
        display: none;
      }

      p{
        width: 60vw;
      }

    }
  }


}









