

.projets{

  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;

  text-align: start;


  a{
    margin-top: 25vh;
    margin-bottom: 25vh;

    text-decoration: none;
    color: #FFF;

    section{

      display: flex;
      flex-direction: column;


      padding-right: 5%;

      h3{
        font-family: 'Reenie Beanie', cursive;
        font-size: 44px;
        font-weight: normal;

        letter-spacing: 5px;

        margin: 0;
        padding: 0;
      }

      .imageNum{

        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;


        img{
          width: 40vw;
        }

        p{
          font-family: 'Red Hat Display', sans-serif;
          font-size: 67px;
          letter-spacing: 20px;

          padding: 0;
          margin: 0;

          color: #727272;
        }

      }

    }

  }

}


@media screen and (max-width: $breakpoint-md) {

  .projets{
    a{
      section{

        h3{
          font-size: 30px;
        }

        .imageNum{

          img{
            width: 75vw;
          }

          p{
            display: none;
          }

        }

      }
    }
  }

}



