.divProjet {

  padding-left: 10vw;

  width: 95vw;
  height: 75vh;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h2 {
    padding-top: 10vh;

    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 67px;
  }

  div {
    display: flex;
    flex-direction: row;

    img{
      width: 40vw;
    }

    .descriptionProjet{

      margin-left: 5vw;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h3{
        margin: 0;
        font-size: 30px;
      }

      p {
        text-align: left;

        margin-bottom: 20vh;
        width: 25vw;
      }

      .logosAppProjet{
        i{
          color: white;
          font-size: 50px;
          margin-right: 5vh;
        }

        img{
          height: 50px;
          margin-right: 5vh;

          width: 50px;
        }

      }

    }

  }
}

@media screen and (max-width: $breakpoint-md) {

  .divProjet{

    display: block;

    h2{
      padding-top: 13vh;

      font-size: 40px;
      text-align: left;
    }

    div{
      flex-direction: column;

      img{
        width: 80vw;
        margin-bottom: 5vh;
      }

      .descriptionProjet{

        margin-left: 0;

        p{
          width: 80vw;
          margin-bottom: 5vh;
        }

        .logosAppProjet{
          display: flex;
          flex-direction: row;
        }

      }

    }
  }

  .footerProjet{
    padding-top: 3vh;
  }

}
