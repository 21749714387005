

footer{

  margin-top: 30vh;
  margin-bottom: 2vh;

  small{

    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: bold;

    color: #878787;

  }

}