//FICHIER DE CONFIGURATION POUR LES VARIABLES DE POLICE


/// Grosseur des textes
$fz-normal: rem(16);

$lh-normal: 1.5;

/// Font family

$ff-normal: 'Roboto', Helvetica, Arial, sans-serif;

/// Fonts weight
$fw-light: 300;
$fw-regular: 400;
$fw-semibold: 500;
$fw-bold: 700;
