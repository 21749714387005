

.btnCV{

  text-decoration: none;
  color: $white;



  p{
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 12px;
    letter-spacing: 4px;

    border: solid 2px #FF9797;

    width: 258px;
    height: 51px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.5s;

  }

}


@media screen and (min-width: $breakpoint-md) {
  .btnCV {
    p {
      &:hover {
        background-color: #FF9797;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {

  .btnCV {
    p {
      width: 175px;
      height: 51px;
    }
  }

}
