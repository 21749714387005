// STYLE DE BASE SUR LA BALISE BODY

body{

  @include font($ff-normal, $fz-normal, $color-text-normal, $lh-normal);

  background-color: $color-bg-page;

  text-align: center;

  scroll-behavior: smooth;
}



