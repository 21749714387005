

header{

  display: flex;
  flex-direction: column;

  position: fixed;

  margin-left: 2vw;

  a{
    display: flex;

    width: 38px;
    margin-top: 4vh;

    img{
      width: 38px;
      height: 28px;
    }
  }

  .menuFixed{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75vh;

    margin-left: 0.5vw;

    visibility: visible;
    opacity: 1;
    transition: 1s;

    a{

      text-decoration: none;

      .headMenu{
        writing-mode: vertical-rl;
        text-orientation: upright;
        color: #FFF;

        i{
          display: none;
        }

        p{
          margin: 0;
          padding: 8px 0 2px 0;

          border-style: solid none;
          border-color: #AD6B6B;
          border-width: 3px;

          text-transform: uppercase;
          line-height: 110%;
          letter-spacing: 3px;

          transition: 1s;

            &:hover{
              border-width: 5vh;
              cursor: pointer;
            }
          }

        }

      }

    }

  }

  .headCourriel{
    position: fixed;
    margin-right: 2.5vw;

    right: 0;
    top: 35vh;

    writing-mode: vertical-rl;
    letter-spacing: 7px;

    p{
      margin: 0;
      color: #444;
      transition: 1s;

      &:hover{
        color: white;
      }
    }

}



@media screen and (max-width: $breakpoint-md) {
  header {

    flex-direction: row;
    justify-content: center;

    position: fixed;
    top: 4vh;

    a{
      margin-top: 0;
    }

    .menuFixed {

      height: auto;

      a {
        .headMenu {
          i {

            cursor: pointer;

            font-size: 30px;

            display: block;
            position: fixed;
            right: 2vw;
            top: 4vh;
          }
          p{
            display: none;
          }

        }
      }
    }

    .headCourriel{
      display: none;
    }


  }
}