.divContact{

  padding-left: 25vw;

  width: 95vw;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h2{
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 67px;
  }

  .btnCV{
    margin-top: 5vh;
  }

  h3{
    font-size: 35px;
    margin-top: 0;
  }

  div{

    width: 50%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    i{
      color: white;

      font-size: 50px;

      margin: 5vh 0 18vh 0;

      &:hover{

        color: #AD6B6B;

      }

    }
  }


}

.footerContact{
  margin-top: 25vh;
}





@media screen and (max-width: $breakpoint-md) {

  .divContact {

    padding-left: 13vw;

    h2 {
      padding-top: 13vh;

      font-size: 40px;
    }

    h3{
      font-size: 32px;
    }

    div{

      i{
        margin: 5vh 5vw 18vh 0;
      }

    }

  }
}