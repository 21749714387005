@charset "UTF-8";
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  color: #fff;
  line-height: 1.5;
  background-color: #1D1D1D;
  text-align: center;
  scroll-behavior: smooth;
}

.divExitMenu {
  position: fixed;
  left: 0.5vw;
  top: 40vh;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: 1s;
}

.divExitMenu a {
  text-decoration: none;
  color: #878787;
}

.divExitMenu a .menuExit {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 38px;
  font-weight: bold;
  margin-left: 1.8vw;
}

.divExitMenu a:hover {
  color: #fff;
  cursor: pointer;
}

.menu {
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #1D1D1D;
}

.menu .listeMenu {
  height: 93vh;
  margin-left: 15vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu .listeMenu ul {
  margin-top: 9vh;
}

.menu .listeMenu ul a {
  text-decoration: none;
}

.menu .listeMenu ul a li {
  position: relative;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: bolder;
  font-size: 41px;
  color: #878787;
  text-transform: uppercase;
  letter-spacing: 10px;
  line-height: 200%;
  list-style: none;
  text-align: start;
}

.menu i {
  position: fixed;
  right: 3vw;
  color: white;
  font-size: 30px;
}

.menu i:hover {
  color: #AD6B6B;
}

@media screen and (max-width: 768px) {
  .divExitMenu {
    display: block;
    position: fixed;
    left: auto;
    right: 2vw;
    top: -1vh;
    width: 45px;
  }
  .menu .listeMenu ul a li {
    margin-top: 5vh;
    color: white;
  }
}

@media screen and (min-width: 768px) {
  .menu .listeMenu ul a .liAccueil:hover {
    color: #fff;
    transition: 0.1s;
  }
  .menu .listeMenu ul a .liAccueil:hover:before {
    transition: 1s;
    border-bottom: 10px solid #AD6B6B;
    position: absolute;
    content: "ACCUEIL";
    width: 150%;
    height: 55%;
  }
  .menu .listeMenu ul a .liProjets:hover {
    color: #fff;
    transition: 0.1s;
  }
  .menu .listeMenu ul a .liProjets:hover:before {
    transition: 1s;
    border-bottom: 10px solid #AD6B6B;
    position: absolute;
    content: "PROJETS";
    width: 150%;
    height: 55%;
  }
  .menu .listeMenu ul a .liContact:hover {
    color: #fff;
    transition: 0.1s;
  }
  .menu .listeMenu ul a .liContact:hover:before {
    transition: 1s;
    border-bottom: 10px solid #AD6B6B;
    position: absolute;
    content: "CONTACT";
    width: 150%;
    height: 55%;
  }
  .menu .listeMenu ul a .liApropos:hover {
    color: #fff;
    transition: 0.1s;
  }
  .menu .listeMenu ul a .liApropos:hover:before {
    transition: 1s;
    border-bottom: 10px solid #AD6B6B;
    position: absolute;
    content: "À PROPOS";
    width: 150%;
    height: 55%;
  }
}

.projets {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;
}

.projets a {
  margin-top: 25vh;
  margin-bottom: 25vh;
  text-decoration: none;
  color: #FFF;
}

.projets a section {
  display: flex;
  flex-direction: column;
  padding-right: 5%;
}

.projets a section h3 {
  font-family: 'Reenie Beanie', cursive;
  font-size: 44px;
  font-weight: normal;
  letter-spacing: 5px;
  margin: 0;
  padding: 0;
}

.projets a section .imageNum {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}

.projets a section .imageNum img {
  width: 40vw;
}

.projets a section .imageNum p {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 67px;
  letter-spacing: 20px;
  padding: 0;
  margin: 0;
  color: #727272;
}

@media screen and (max-width: 768px) {
  .projets a section h3 {
    font-size: 30px;
  }
  .projets a section .imageNum img {
    width: 75vw;
  }
  .projets a section .imageNum p {
    display: none;
  }
}

header {
  display: flex;
  flex-direction: column;
  position: fixed;
  margin-left: 2vw;
}

header a {
  display: flex;
  width: 38px;
  margin-top: 4vh;
}

header a img {
  width: 38px;
  height: 28px;
}

header .menuFixed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75vh;
  margin-left: 0.5vw;
  visibility: visible;
  opacity: 1;
  transition: 1s;
}

header .menuFixed a {
  text-decoration: none;
}

header .menuFixed a .headMenu {
  writing-mode: vertical-rl;
  text-orientation: upright;
  color: #FFF;
}

header .menuFixed a .headMenu i {
  display: none;
}

header .menuFixed a .headMenu p {
  margin: 0;
  padding: 8px 0 2px 0;
  border-style: solid none;
  border-color: #AD6B6B;
  border-width: 3px;
  text-transform: uppercase;
  line-height: 110%;
  letter-spacing: 3px;
  transition: 1s;
}

header .menuFixed a .headMenu p:hover {
  border-width: 5vh;
  cursor: pointer;
}

.headCourriel {
  position: fixed;
  margin-right: 2.5vw;
  right: 0;
  top: 35vh;
  writing-mode: vertical-rl;
  letter-spacing: 7px;
}

.headCourriel p {
  margin: 0;
  color: #444;
  transition: 1s;
}

.headCourriel p:hover {
  color: white;
}

@media screen and (max-width: 768px) {
  header {
    flex-direction: row;
    justify-content: center;
    position: fixed;
    top: 4vh;
  }
  header a {
    margin-top: 0;
  }
  header .menuFixed {
    height: auto;
  }
  header .menuFixed a .headMenu i {
    cursor: pointer;
    font-size: 30px;
    display: block;
    position: fixed;
    right: 2vw;
    top: 4vh;
  }
  header .menuFixed a .headMenu p {
    display: none;
  }
  header .headCourriel {
    display: none;
  }
}

.hero {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 25vh;
}

.hero div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5%;
  margin-right: 5%;
}

.hero div h1 {
  margin: 0;
  padding: 0;
  font-family: 'Reenie Beanie', cursive;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 45px;
  line-height: 82.4%;
  letter-spacing: 30px;
}

.hero div h2 {
  font-family: 'Reenie Beanie', cursive;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 82.4%;
  letter-spacing: 10px;
}

.hero img {
  margin-top: 26vh;
  height: 50vh;
}

.hero .btnCV {
  margin-top: 9vh;
  margin-left: 5vw;
}

.hero .btnCV p {
  margin-left: 30%;
}

@media screen and (max-width: 768px) {
  .hero .wrapper {
    display: flex;
    flex-direction: row;
  }
  .hero .wrapper h1 {
    writing-mode: vertical-rl;
    text-orientation: upright;
    letter-spacing: 2px;
  }
  .hero .wrapper h2 {
    display: none;
  }
  .hero div img {
    height: 40vh;
    width: auto;
  }
}

footer {
  margin-top: 30vh;
  margin-bottom: 2vh;
}

footer small {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #878787;
}

.btnCV {
  text-decoration: none;
  color: #fff;
}

.btnCV p {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 12px;
  letter-spacing: 4px;
  border: solid 2px #FF9797;
  width: 258px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

@media screen and (min-width: 768px) {
  .btnCV p:hover {
    background-color: #FF9797;
  }
}

@media screen and (max-width: 768px) {
  .btnCV p {
    width: 175px;
    height: 51px;
  }
}

.divAPropos {
  padding-left: 25vw;
  width: 95vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.divAPropos h2 {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 67px;
}

.divAPropos div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divAPropos div .imageAPropos {
  height: 40vh;
  width: 25vw;
}

.divAPropos div p {
  text-align: left;
  width: 25vw;
}

.divAPropos .btnCV {
  margin-top: 5vh;
}

.footerAPropos {
  margin-top: 13vh;
  margin-bottom: 0;
}

@media screen and (max-width: 1190px) {
  .divAPropos h2 {
    padding-top: 13vh;
    font-size: 40px;
  }
  .divAPropos div .imageAPropos {
    display: none;
  }
  .divAPropos div p {
    width: 60vw;
  }
}

.divProjet {
  padding-left: 10vw;
  width: 95vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.divProjet h2 {
  padding-top: 10vh;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 67px;
}

.divProjet div {
  display: flex;
  flex-direction: row;
}

.divProjet div img {
  width: 40vw;
}

.divProjet div .descriptionProjet {
  margin-left: 5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.divProjet div .descriptionProjet h3 {
  margin: 0;
  font-size: 30px;
}

.divProjet div .descriptionProjet p {
  text-align: left;
  margin-bottom: 20vh;
  width: 25vw;
}

.divProjet div .descriptionProjet .logosAppProjet i {
  color: white;
  font-size: 50px;
  margin-right: 5vh;
}

.divProjet div .descriptionProjet .logosAppProjet img {
  height: 50px;
  margin-right: 5vh;
  width: 50px;
}

@media screen and (max-width: 768px) {
  .divProjet {
    display: block;
  }
  .divProjet h2 {
    padding-top: 13vh;
    font-size: 40px;
    text-align: left;
  }
  .divProjet div {
    flex-direction: column;
  }
  .divProjet div img {
    width: 80vw;
    margin-bottom: 5vh;
  }
  .divProjet div .descriptionProjet {
    margin-left: 0;
  }
  .divProjet div .descriptionProjet p {
    width: 80vw;
    margin-bottom: 5vh;
  }
  .divProjet div .descriptionProjet .logosAppProjet {
    display: flex;
    flex-direction: row;
  }
  .footerProjet {
    padding-top: 3vh;
  }
}

.divContact {
  padding-left: 25vw;
  width: 95vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.divContact h2 {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 67px;
}

.divContact .btnCV {
  margin-top: 5vh;
}

.divContact h3 {
  font-size: 35px;
  margin-top: 0;
}

.divContact div {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.divContact div i {
  color: white;
  font-size: 50px;
  margin: 5vh 0 18vh 0;
}

.divContact div i:hover {
  color: #AD6B6B;
}

.footerContact {
  margin-top: 25vh;
}

@media screen and (max-width: 768px) {
  .divContact {
    padding-left: 13vw;
  }
  .divContact h2 {
    padding-top: 13vh;
    font-size: 40px;
  }
  .divContact h3 {
    font-size: 32px;
  }
  .divContact div i {
    margin: 5vh 5vw 18vh 0;
  }
}

.div404 {
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: start;
  margin-left: 22vw;
}

.div404 h2 {
  align-self: start;
  font-size: 200px;
  -webkit-text-stroke: 3px #FF9797;
  color: rgba(0, 0, 0, 0);
  margin-top: 5vh;
  margin-bottom: 0;
}

.div404 h4 {
  text-align: start;
  font-size: 90px;
  -webkit-text-stroke: 3px #FF9797;
  color: rgba(0, 0, 0, 0);
  margin-top: 0;
  margin-bottom: 0;
}

.div404 .retour404 {
  align-self: start;
  margin-top: 5vh;
}

.div404 .retour404 i {
  font-size: 75px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 3px #FF9797;
  transition: 0.5s;
}

.div404 .retour404 i:hover {
  color: #FF9797;
}

.papineau404 {
  position: fixed;
  z-index: -100;
  bottom: 0;
}

@media screen and (max-width: 1375px) {
  .div404 h2 {
    font-size: 150px;
  }
  .div404 h4 {
    font-size: 70px;
  }
  .div404 .retour404 i {
    font-size: 50px;
  }
  .papineau404 {
    right: 0;
  }
}

@media screen and (max-width: 1150px) {
  .div404 {
    margin-left: 5vw;
  }
}

@media screen and (max-width: 950px) {
  .div404 h2 {
    font-size: 150px;
  }
  .papineau404 {
    right: -300px;
  }
}

@media screen and (max-width: 450px) {
  .div404 h2 {
    margin-top: -25vh;
    font-size: 150px;
  }
  .div404 h4 {
    font-size: 40px;
    -webkit-text-stroke: 1px #FF9797;
  }
  .papineau404 {
    bottom: -90vh;
    right: -200px;
  }
}

.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.u-hidden {
  display: none !important;
}

@media print {
  .hidden-print {
    display: none !important;
  }
}
